import { Box, Card, createStyles, Group, Text, useMantineTheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { TFunction } from 'i18next';
import Image from 'next/future/image';
import Link from 'next/link';
import { useState } from 'react';

import { IPage, IProduct, ITheme, ValidLocales } from '../../../typings';
import { getImageFitValue, getProductPrice, imageLoader } from '../../../utils';
import { ProductCardActions } from '../../common/ProductCardActions';
import { ProductCardMobileActions } from '../../common/ProductCardMobileActions';
import { ProductPlaceholder } from '../../common/ProductPlaceholder';
import ProductPrice from './ProductPrice';

const useStyles = createStyles(() => ({
  thumbImg: {
    transition: 'all .4s ease-in-out',

    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  opacity0: {
    opacity: 0,
  },
  opacity1: {
    opacity: 1,
  },
}));

type Props = {
  withinBuilder?: boolean;
  theme: ITheme<IPage>;
  product: IProduct;
  imageScale: string;
  isMobile?: boolean;
  txtColor?: string;
  currency?: string;
  priceDecimalPoints?: number;
  priceTemplate?: string;
  textAlign?: 'start' | 'center' | 'end';
  locale?: ValidLocales;
  enableImageHoverEffect?: boolean;
  priority?: boolean;
  showSecondaryImageOnHover?: boolean;
  useShopAsCatalogue: boolean;
  isProductSoldOut: boolean;
  hasAddToCart: boolean;
  isAddToCartLoading?: boolean;
  isOnWishlist: (id: number) => boolean;
  toggleWishlist: (id: number) => void;
  t: TFunction;
  handleAddToCart: (product: IProduct) => void;
};

const ProductCard = ({
  withinBuilder,
  theme,
  product,
  imageScale,
  isMobile,
  currency,
  priceDecimalPoints,
  priceTemplate,
  locale,
  textAlign = 'center',
  txtColor,
  priority,
  enableImageHoverEffect,
  showSecondaryImageOnHover,
  useShopAsCatalogue,
  isProductSoldOut,
  hasAddToCart,
  isAddToCartLoading,
  t,
  handleAddToCart,
  isOnWishlist,
  toggleWishlist,
}: Props) => {
  const mantineTheme = useMantineTheme();
  const { cx, classes } = useStyles();
  const { hovered: thumbHovered, ref: thumbRef } = useHover();
  const thumbUrl = product.thumbUrls?.[0] || product.thumbUrl;
  const secondaryThumbUrl = product.secondaryThumbUrls?.[0];
  const [isCardBtnVisible, setIsCardBtnVisible] = useState<boolean>(false);

  return (
    <Card
      px={isMobile ? 15 : 18}
      py={15}
      sx={{ cursor: 'pointer', borderRadius: theme.radius, backgroundColor: 'transparent' }}
      onMouseEnter={() => !isProductSoldOut && !isMobile && setIsCardBtnVisible(true)}
      onMouseLeave={() => setIsCardBtnVisible(false)}
    >
      <Box component={Link} href={`/shop/products/${product.handle}`}>
        <Box
          ref={thumbRef}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: isMobile ? 165 : 300,
            position: 'relative',
            overflow: 'hidden',
            backgroundColor: thumbUrl ? 'transparent' : mantineTheme.colors['light-gray'][0],
          }}
        >
          {thumbUrl ? (
            <>
              <Image
                fill
                src={thumbUrl}
                alt={`${product.name} hover image`}
                style={{ objectFit: getImageFitValue(imageScale) }}
                className={cx(classes.opacity1, {
                  [classes.thumbImg]: enableImageHoverEffect,
                  [classes.opacity0]:
                    thumbHovered && product.secondaryThumbUrls && showSecondaryImageOnHover,
                })}
                priority={priority}
                loader={imageLoader({ urls: product.thumbUrls, withinBuilder })}
                sizes="(max-width: 36em) 50vw, (max-width: 75em) 33vw, 25vw"
              />
              {showSecondaryImageOnHover && secondaryThumbUrl ? (
                <Image
                  src={secondaryThumbUrl}
                  alt={`${product.name}-2nd-img`}
                  fill
                  style={{ objectFit: getImageFitValue(imageScale) }}
                  className={cx(classes.opacity0, {
                    [classes.thumbImg]: enableImageHoverEffect,
                    [classes.opacity1]: thumbHovered,
                  })}
                  priority={priority}
                  loader={imageLoader({ urls: product.secondaryThumbUrls, withinBuilder })}
                  sizes="(max-width: 36em) 50vw, (max-width: 75em) 33vw, 25vw"
                />
              ) : null}
            </>
          ) : (
            <ProductPlaceholder priority={priority} />
          )}
        </Box>
      </Box>
      <Box
        sx={{ textDecoration: 'none', color: 'inherit' }}
        component={Link}
        href={`/shop/products/${product.handle}`}
      >
        <Group mt={20} sx={{ flexDirection: 'column' }} spacing={10}>
          <Text size={18} weight={700} color={txtColor} sx={{ width: '100%', textAlign }}>
            {product.name}
          </Text>
          {product.vendor ? (
            <Text
              size={16}
              weight={300}
              color={txtColor}
              transform="uppercase"
              sx={{ width: '100%', textAlign }}
            >
              {product.vendor}
            </Text>
          ) : null}
          <ProductPrice
            theme={theme}
            price={getProductPrice(product)}
            locale={locale}
            currency={currency}
            priceDecimalPoints={priceDecimalPoints}
            priceTemplate={priceTemplate}
          />
        </Group>
      </Box>

      {!useShopAsCatalogue && isCardBtnVisible && (
        <ProductCardActions
          top={225}
          t={t}
          product={product}
          hasAddToCart={hasAddToCart}
          isAddToCartLoading={isAddToCartLoading}
          onAddToCart={() => handleAddToCart(product)}
        />
      )}

      {!useShopAsCatalogue && hasAddToCart && isMobile && (
        <ProductCardMobileActions
          product={product}
          isProductSoldOut={isProductSoldOut}
          isOnWishlist={isOnWishlist}
          toggleWishlist={toggleWishlist}
          handleAddToCart={() => handleAddToCart(product)}
        />
      )}
    </Card>
  );
};

export default ProductCard;

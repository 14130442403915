import { Box, Button } from '@mantine/core';
import { TFunction } from 'i18next';
import { MouseEvent } from 'react';
import { IProduct } from 'shared';

type Props = {
  product: IProduct;
  isRelatedProduct?: boolean;
  hasAddToCart: boolean;
  isAddToCartLoading?: boolean;
  top?: number;
  t: TFunction;
  onQuickView?: (handle: string) => void;
  onAddToCart: () => void;
};

export const ProductCardActions = ({
  product,
  isRelatedProduct,
  hasAddToCart,
  isAddToCartLoading,
  top = 300,
  t,
  onQuickView,
  onAddToCart,
}: Props) => {
  return hasAddToCart ? (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top,
        right: 0,
      }}
    >
      <Button
        size="lg"
        py={15}
        sx={{ width: '80%' }}
        variant="outline"
        styles={{
          label: { overflow: 'visible' },
        }}
        loading={isAddToCartLoading}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onAddToCart();
        }}
      >
        {product.variants.length || product.hasExtras ? t('select-options') : t('add-to-cart')}
      </Button>
    </Box>
  ) : !isRelatedProduct && onQuickView ? (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 300,
        right: 0,
      }}
    >
      <Button
        size="lg"
        py={15}
        sx={{ width: '80%' }}
        variant="outline"
        styles={{
          label: { overflow: 'visible' },
        }}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          onQuickView?.(product.handle);
        }}
      >
        {t('quick-view')}
      </Button>
    </Box>
  ) : null;
};

import { Box, Grid } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { TFunction } from 'i18next';
import { useMemo } from 'react';

import {
  ICompany,
  IProduct,
  IPurchaseProduct,
  IPurchaseProductExtras,
  IPurchaseProductFiles,
  ISubExtraOptionAnswer,
  IVariant,
  ValidLocales,
} from '../../typings';
import { hasFeature, hasPolicies } from '../../utils';
import NotifyMeWhenBackInStockModal from './NotifyMeWhenBackInStockModal';
import PoliciesModal from './PoliciesModal';
import ProductBasicInfo from './ProductBasicInfo';
import ProductDesc from './ProductDesc';
import ProductGallery from './ProductGallery';
import ProductPanel from './ProductPanel';

type Props = {
  withinBuilder?: boolean;
  isQuickView?: boolean;
  isFeaturedProduct?: boolean;
  company: ICompany;
  product: IProduct;
  selectedVariant: IVariant | null;
  povs: { [index: number]: string } | null;
  extras: IPurchaseProductExtras<string> | null;
  extrasFiles: IPurchaseProductFiles<File> | null;
  quantity: number;
  pricingDetails: IPurchaseProduct['pricingDetails'] | null;
  isLessThanMOT: boolean;
  isAddToCartDisabled?: boolean;
  isAddToCartLoading: boolean;
  isBuyNowLoading?: boolean;
  locale?: ValidLocales;
  isMobile: boolean;
  isNotifyMeLoading: boolean;
  isNotifyMeSuccessful: boolean;
  isUploadFileLoading?: boolean;
  quickViewOffset?: { left?: number; top?: number };
  customLabels: Record<string, string>;
  isOnWishlist: (id: number) => boolean;
  toggleWishlist: (id: number) => void;
  onQuantityChange: (value: number) => void;
  onPricingDetailsChange: (value: IPurchaseProduct['pricingDetails'] | null) => void;
  onOptionValueChange: (optionId: number, value: string) => void;
  onExtraChange: (
    extraId: number,
    value: ISubExtraOptionAnswer<string> | ISubExtraOptionAnswer<string>[],
  ) => void;
  onExtrasFileChange: (value: { [x: number]: ISubExtraOptionAnswer<File> }) => void;
  addToCart: ({ redirectToCheckout }: { redirectToCheckout: boolean }) => Promise<void>;
  buyItNow: () => void;
  notifyMe: (id: number, email: string) => Promise<void>;
  t: TFunction;
};

export const ProductPreview = ({
  withinBuilder,
  isQuickView,
  isFeaturedProduct,
  company,
  product,
  selectedVariant,
  povs,
  extras,
  extrasFiles,
  quantity,
  pricingDetails,
  isLessThanMOT,
  isAddToCartDisabled,
  isAddToCartLoading,
  isBuyNowLoading,
  locale,
  isMobile,
  isNotifyMeLoading,
  isNotifyMeSuccessful,
  isUploadFileLoading,
  quickViewOffset,
  customLabels,
  isOnWishlist,
  toggleWishlist,
  onQuantityChange,
  onPricingDetailsChange,
  onOptionValueChange,
  onExtraChange,
  onExtrasFileChange,
  addToCart,
  buyItNow,
  notifyMe,
  t,
}: Props) => {
  const [isPoliciesModalOpen, { close: closePoliciesModal, open: openPoliciesModal }] =
    useDisclosure(false);

  const [isNotifyMeModalOpen, { close: closeNotifyMeModal, open: openNotifyMeModal }] =
    useDisclosure(false);

  const isOutOfStock = useMemo(
    () => !!(product?.isTracked && selectedVariant && selectedVariant.quantity <= 0),
    [product, selectedVariant],
  );

  return (
    <Box>
      <Grid gutter={isMobile ? 0 : 70} mr={isMobile || isQuickView ? 0 : 20}>
        <Grid.Col span={isMobile ? 12 : 7}>
          <ProductGallery
            withinBuilder={withinBuilder}
            product={product}
            variantImagesIds={selectedVariant?.imageIds}
            zoomOnHover={hasFeature(company, 'zoom_images')}
            offset={quickViewOffset}
            isMobile={isMobile}
            isFeaturedProduct={isFeaturedProduct}
          />
          {!isQuickView && !isMobile && !isFeaturedProduct && product.description ? (
            <ProductDesc
              isMobile={isMobile}
              desc={product.description}
              useShopAsCatalogue={hasFeature(company, 'use_website_as_catalogue')}
              t={t}
            />
          ) : null}
        </Grid.Col>
        <Grid.Col span={isMobile ? 12 : 5}>
          <Box>
            <ProductBasicInfo
              product={product}
              selectedVariant={selectedVariant}
              pricingDetails={pricingDetails}
              hideDiscountTag={hasFeature(company, 'hide_discount_tag')}
              hideItemsLeft={hasFeature(company, 'hide_product_items_left')}
              isOutOfStock={isOutOfStock}
              useShopAsCatalogue={hasFeature(company, 'use_website_as_catalogue')}
              currency={company.paymentCurrency}
              priceDecimalPoints={company.priceDecimalPoints}
              locale={locale}
              isMobile={isMobile}
              t={t}
            />
            <ProductPanel
              product={product}
              selectedVariant={selectedVariant}
              povs={povs}
              extras={extras}
              extrasFiles={extrasFiles}
              isColorsDropdown={hasFeature(company, 'show_colors_as_dropdown')}
              useShopAsCatalogue={hasFeature(company, 'use_website_as_catalogue')}
              hideQuantitySelector={hasFeature(company, 'hide_quantity_selector')}
              useNotifyMe={hasFeature(company, 'back_in_stock_notification')}
              hasWishlist={hasFeature(company, 'wishlist')}
              isOutOfStock={isOutOfStock}
              hasSympl={company.useSymplPayment}
              hasTabby={company.useTabbyPayment}
              quantity={quantity}
              locale={locale}
              currency={company.paymentCurrency}
              priceDecimalPoints={company.priceDecimalPoints}
              symplStoreCode={company.symplStoreCode || ''}
              pricingDetails={pricingDetails}
              minimumOrderTotal={company.minimumOrderTotal || 0}
              isLessThanMOT={isLessThanMOT}
              hasPolicies={hasPolicies(company)}
              isMobile={isMobile}
              isAddToCartDisabled={isAddToCartDisabled}
              isAddToCartLoading={isAddToCartLoading}
              isBuyNowLoading={isBuyNowLoading}
              isUploadFileLoading={isUploadFileLoading}
              customLabels={customLabels}
              isOnWishlist={isOnWishlist}
              toggleWishlist={toggleWishlist}
              onQuantityChange={onQuantityChange}
              onPricingDetailsChange={onPricingDetailsChange}
              onOptionValueChange={onOptionValueChange}
              onExtraChange={onExtraChange}
              onExtrasFileChange={onExtrasFileChange}
              openPoliciesModal={openPoliciesModal}
              openNotifyMeModal={openNotifyMeModal}
              addToCart={addToCart}
              buyItNow={buyItNow}
              t={t}
            />
          </Box>
          {isMobile && !isFeaturedProduct && product.description ? (
            <ProductDesc
              isMobile={isMobile}
              desc={product.description}
              useShopAsCatalogue={hasFeature(company, 'use_website_as_catalogue')}
              t={t}
            />
          ) : null}
        </Grid.Col>
      </Grid>
      <PoliciesModal
        isMobile={isMobile}
        company={company}
        isOpen={isPoliciesModalOpen}
        onClose={closePoliciesModal}
        t={t}
      />
      <NotifyMeWhenBackInStockModal
        isMobile={isMobile}
        product={product}
        selectedVariant={selectedVariant}
        isOpen={isNotifyMeModalOpen}
        onClose={closeNotifyMeModal}
        t={t}
        notifyMe={notifyMe}
        isNotifyMeLoading={isNotifyMeLoading}
        isNotifyMeSuccessful={isNotifyMeSuccessful}
      />
    </Box>
  );
};

import { ActionIcon, useMantineTheme } from '@mantine/core';
import { IconShoppingBag } from '@tabler/icons-react';

type Props = {
  id: number;
  isDisabled: boolean;
  onAddToCart: (id: number) => void;
};

export const ProductAddToCartIcon = ({ id, isDisabled, onAddToCart }: Props) => {
  const mantineTheme = useMantineTheme();

  return (
    <ActionIcon
      p={5}
      size={30}
      maw={30}
      variant="filled"
      color="btn-secondary"
      disabled={isDisabled}
      onClick={(e: any) => {
        e.preventDefault();
        onAddToCart(id);
      }}
    >
      {/* we have to use style instead of color & fill due to bug in tabler, https://github.com/tabler/tabler-icons/issues/893 */}
      <IconShoppingBag size={24} style={{ color: mantineTheme.colors['btn-secondary-label'][0] }} />
    </ActionIcon>
  );
};

import { Group } from '@mantine/core';
import { IProduct, ProductAddToCartIcon, ProductWishlistIcon } from 'shared';

type Props = {
  product: IProduct;
  isProductSoldOut: boolean;
  isOnWishlist: (id: number) => boolean;
  toggleWishlist: (id: number) => void;
  handleAddToCart: () => void;
};

export const ProductCardMobileActions = ({
  product,
  isProductSoldOut,
  isOnWishlist,
  toggleWishlist,
  handleAddToCart,
}: Props) => {
  return (
    <Group
      spacing={5}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 135,
        right: 0,
      }}
    >
      <ProductAddToCartIcon
        id={product.id}
        isDisabled={isProductSoldOut}
        onAddToCart={handleAddToCart}
      />
      <ProductWishlistIcon
        id={product.id}
        withinCard
        isOnWishlist={isOnWishlist}
        toggleWishlist={toggleWishlist}
      />
    </Group>
  );
};

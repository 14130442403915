import { useState } from "react";
import { ICompany, ITheme, IThemePage, IProductComponent, ZammitComponent, ProductPreview } from "shared";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useTranslation } from "react-i18next";
import { useProducts } from "../../../hooks/queries/useProducts";
import { useProduct } from "../../../hooks/queries/useProduct";

type Props = { company: ICompany; component: IProductComponent; theme: ITheme<IThemePage> };
type ChildrenProps = {
  company: ICompany;
  component: IProductComponent;
  theme: ITheme<IThemePage>;
  locale: any;
  isMobile: boolean;
};

const ComponentWithProductModel = ({ company, component, theme }: Props) => {
  const showingMultipleProducts = ["featured_products_grid", "featured_products_carousel"].includes(component.key);
  const { isMobile, locale } = useBuilderQuery();

  return showingMultipleProducts ? (
    <ComponentWithMultipleProducts
      company={company}
      component={component}
      theme={theme}
      locale={locale}
      isMobile={isMobile}
    />
  ) : (
    <ComponentWithSingleProduct
      company={company}
      component={component}
      theme={theme}
      locale={locale}
      isMobile={isMobile}
    />
  );
};

const ComponentWithSingleProduct = ({ company, component, theme, locale, isMobile }: ChildrenProps) => {
  const { t } = useTranslation(["productDetails"]);
  const { data } = useProduct(String(component.modelIds));

  const [quantity, setQuantity] = useState<number>(data?.minQuantity || 1);

  const dummyFunction = () => {
    return;
  };

  const dummyAsync = async () => {
    return;
  };

  return (
    <ZammitComponent
      company={company}
      component={{ ...component, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
    >
      {data ? (
        <ProductPreview
          withinBuilder
          isFeaturedProduct
          company={company}
          product={data}
          selectedVariant={null}
          povs={null}
          extras={null}
          extrasFiles={null}
          quantity={quantity}
          pricingDetails={null}
          isLessThanMOT={false}
          isAddToCartLoading={false}
          locale={locale}
          isMobile={isMobile}
          isNotifyMeLoading={false}
          isNotifyMeSuccessful={false}
          customLabels={(locale === "en" ? theme.labelsEn : theme.labelsAr) || {}}
          isOnWishlist={() => false}
          toggleWishlist={dummyFunction}
          onQuantityChange={setQuantity}
          onPricingDetailsChange={dummyFunction}
          onOptionValueChange={dummyFunction}
          onExtraChange={dummyFunction}
          onExtrasFileChange={dummyFunction}
          addToCart={dummyAsync}
          buyItNow={dummyAsync}
          notifyMe={dummyAsync}
          t={t}
        />
      ) : null}
    </ZammitComponent>
  );
};

const ComponentWithMultipleProducts = ({ company, component, theme, locale, isMobile }: ChildrenProps) => {
  const { t } = useTranslation(["productCard"]);
  const { data } = useProducts({ ids: component.modelIds });

  return (
    <ZammitComponent
      withinBuilder
      company={company}
      component={{ ...component, data: component.modelIds.length > 0 ? data : [] }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
      extraProps={{
        t,
        isOnWishlist: () => null,
        toggleWishlist: () => null,
        handleAddToCart: () => null,
      }}
    />
  );
};

export default ComponentWithProductModel;

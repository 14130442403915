import Header from "../components/layout/Header";
import ThemeLibrary from "../components/home/themeLibrary";
import ThemesStore from "../components/home/themesStore/ThemesStore";

const Home = () => {
  return (
    <>
      <Header />
      <ThemeLibrary />
      <ThemesStore />
    </>
  );
};

export default Home;
